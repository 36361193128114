<template>
  <v-container class="fill-height">
    <v-row>
      <v-col cols="12">
        <v-card outlined style="width: 500px; max-width: 100%" class="pt-6 pb-4 px-6 ma-auto">
          <SignUpForm />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SignUpForm from '../components/SignUpForm';

export default {
  name: 'Signup',

  components: {
    SignUpForm
  },

  computed: {

  },

  methods: {
  },

  async mounted() {

  },

  data: () => ({

  }),
}
</script>

<style lang="scss">

</style>