<template>
  <div class="text-center">
    <template v-if="!showSuccess">
      <h2 class="mb-4 ">Sign Up</h2>
      <v-form ref="form" v-model="valid" @submit.prevent="handleFormSubmit">

        <v-text-field
            v-model.trim="email"
            label="Enter E-mail"
            solo
            outlined
            dense
            flat
            required
            :rules="emailRules"
            class="mb-5 font-size-15"
            hide-details="auto"
            :error-messages="errors.email"
            @change="resetFormValidation"
        ></v-text-field>

        <v-text-field
            v-model.trim="password"
            label="Enter Password"
            solo
            outlined
            dense
            flat
            required
            :rules="passwordRules"
            hide-details="auto"
            class="mb-5 font-size-15"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            :error-messages="errors.password"
            @change="resetFormValidation"
        ></v-text-field>

        <v-text-field
            v-model.trim="repeatPassword"
            label="Repeat Password"
            solo
            outlined
            dense
            flat
            required
            :rules="repeatPasswordRules.concat(passwordConfirmationRule)"
            hide-details="auto"
            class="mb-5 font-size-15"
            :type="showRepeatPassword ? 'text' : 'password'"
            :append-icon="showRepeatPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showRepeatPassword = !showRepeatPassword"
        ></v-text-field>

        <v-text-field
            v-if="false"
            v-model.trim="coupon"
            label="Enter Coupon (optional)"
            solo
            outlined
            dense
            flat
            class="mb-5"
        ></v-text-field>

        <v-btn type="submit" height="40" color="cyan accent-4 " class="custom--btn" dark block depressed>Sign Up</v-btn>

        <div>
          <v-divider class="mt-5 mb-4"></v-divider>

          <small class="d-block"><span class="grey--text">Have an account?</span> <router-link to="/signin">Sign In Now</router-link></small>
        </div>
      </v-form>
    </template>

    <template v-else>
      <v-icon x-large color="success">mdi-checkbox-marked-circle-outline</v-icon>
      <h2 class="mb-4 ">Registered successfully</h2>
      <div class="text--secondary">No access for now</div>
    </template>
  </div>
</template>

<script>
import authApi from '../api/auth';
import { emailRules, passwordRules, requiredRules } from '../js/validators';
import {mapMutations} from "vuex";

export default {
  name: 'SignUpForm',

  computed: {
    passwordConfirmationRule() {
      return () =>
          this.password === this.repeatPassword || 'Password must match';
    }
  },

  methods: {
    ...mapMutations(['setLoading']),
    resetFormValidation() {
      this.errors.email = '';
      this.errors.password = '';
    },
    handleFormSubmit(e) {
      this.resetFormValidation();

      this.$nextTick(async function() {
        if (!this.$refs.form.validate() || !this.valid) { return }

        this.setLoading(true);
        const { status } = await authApi.signup.call(this, {
          email: this.email,
          password: this.password,
        });
        this.setLoading(false);

        if (status) {
          this.showSuccess = true;
        }
      });
    }
  },

  data: () => ({
    valid: false,
    showPassword: false,
    showRepeatPassword: false,
    showSuccess: false,

    coupon: '',

    password: '',
    passwordRules: [...requiredRules, ...passwordRules],

    repeatPassword: '',
    repeatPasswordRules: [ ...requiredRules, ...passwordRules ],

    email: '',
    emailRules: [...requiredRules, ...emailRules],

    errors: { email: '', password: '' }
  }),
}
</script>

<style lang="scss">

</style>
